export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Freudenberg Sealing Technologies (FST) sets the bar for high-quality and innovative sealing solutions. Our product catalog includes the range of FST as well as standard seals from Dichtomatik."},{"hid":"format-detection","name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"stylesheet","href":"https://unpkg.com/intro.js/minified/introjs.min.css"}],"style":[],"script":[{"src":"/js/vendor/mopinion.js","type":"text/javascript"}],"noscript":[],"htmlAttrs":{"lang":"en-US"},"title":"Product Catalog | Freudenberg FST"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null